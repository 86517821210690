import React from 'react';
//import { Link } from "react-router-dom";
import { Translation } from 'react-i18next';
import i18next from 'i18next';
import { TopContent } from './components/TopContent.js';
import { NavTop, NavBar } from './components/Nav.js';
import { Section, Container, OfferCarousel } from './components/Components.js';

import { MyActic } from './components/myactic/MyActic.js';
import { MyActicBenefits } from './components/myactic/MyActicBenefits.js';
import { Achievements, AchievementsCompleted } from './components/myactic/Achievements.js';
import { MyActicLog } from './components/myactic/MyActicLog.js';

import PersonContext from './PersonContext.js';
import { Confirm } from './components/Confirm.js';
import { getDomainCountry } from './config.js'

export class PageMyActic extends React.Component {
  static contextType = PersonContext;

  constructor(props) {
    super();

    this.state = {
      loadingMyActic: true
    };
  }

  componentDidMount() {
    document.title = i18next.t("Headline.MyActic") + ' - ' + i18next.t("SiteName");
    //console.log(this.context.person);
    if (typeof this.context.person.personId === 'undefined') {
      return;
    }
    if (this.context.myactic) {
      this.setState({
        loadingMyActic: false
      });
    } else {
      this.getMyActic();
    }
  }

  getMyActic = () => { 
    this.context.getMyActic()
      .then((loadingDone) => { // done
        if (loadingDone) {
          this.setState({loadingMyActic: false});
        } else {
          setTimeout(() => { 
            this.getMyActic();
          }, 2000);
        }
      })
      .catch(() => { // error
        Confirm({
          'message': i18next.t('Errors.FetchMyActicErrorRetry'),
          onConfirm: () => { 
            this.setState({ loadingMyActic: true });
            this.getMyActic();
          }
        });
        this.setState({loadingMyActic: false});
      });

  }

  render() {


    return (
      <Translation>
        {(t) => (
          <React.Fragment>

            <NavTop />
            <OfferCarousel onClick={() => { this.context.openModal("recruit"); }} title={t('Headline.Recruit')} />
            <TopContent h1={t('Headline.MyActic')} h2={t('Headline.MyPages')}  />

          {/* 
            <Section headline={t('MemberInfo.Headline')}>
              <Container>
                <MemberInfo />
              </Container>
            </Section> 
            */}

{
              getDomainCountry() === 'NO'
              ? (
                <>
              <Section headline={t('MyActic.LevelHeadline')} open={true} buttons={
                <p className="body-text-small m-0 link color-primary" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.context.openModal("myactic");
                }}>{t('MyActic.InfoButton')}</p>
            }>
              <Container>
                <MyActic loadingMyActic={this.state.loadingMyActic} />
              </Container>
            </Section>

            <Section open={true} headline={t('MyActic.BenefitsHeadline')}>
              <Container>
                <MyActicBenefits loadingMyActic={this.state.loadingMyActic} />
              </Container>
            </Section>

            <Section headline={t('Achievements.Headline')}>
              <Container>
                <Achievements />
              </Container>
            </Section>

            <Section headline={t('Achievements.HeadlineCompleted')}>
                  <Container>
                    <AchievementsCompleted />
                  </Container>
            </Section>
            </>
              )
              : null
              }

{
              getDomainCountry() === 'SE'
              ? (
                <Section open={false} headline="Medlemsförmåner">
                <Container>
                  <MyActicBenefits loadingMyActic={this.state.loadingMyActic} />
                </Container>
              </Section>
              )
              : null
              }

            <Section headline={t('Log.Headline')}>
                <Container className="wrapper-log">
                  <MyActicLog />
                </Container>
            </Section>

            
            

            <NavBar />
            
          </React.Fragment>
        )}
      </Translation>
    );
  }
}
