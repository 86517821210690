import React, { useState, useContext } from 'react';
import copy from 'copy-to-clipboard';
import { Translation } from 'react-i18next';
import PersonContext from "../../PersonContext";
import {getDomainCountry} from "../../config.js";
import recruitUrls from "../../config/recruitUrls.json";


export const RecruitModal = (props) => {
  let context = useContext(PersonContext);
  let [isCopies, setCopied] = useState(false);

  let domainCountry = getDomainCountry();
  let linkBase = recruitUrls[domainCountry];
  let recruitUrl = linkBase + context.person.personId.center + 'p' + context.person.personId.id;

  const copyLink = (e) => { 
    e.preventDefault();
    copy(recruitUrl);
    setCopied(true);
    setTimeout(() => { setCopied(false); }, 3000);
  }

  return (
    <Translation>
      {(t) => (
        <React.Fragment>
          <div className="top-content">
            <h1>{t("Recruit.Headline")}</h1>
            <h2>{t("Recruit.SubHeadline")}</h2>
          </div>

          <div className="pb-5" style={{ transform: 'translateY(-1px)' }} />
          <div className="d-flex justify-content-center" style={{ transform: 'translateY(-50%)' }}>
            <button className="btn primary-btn" onClick={copyLink}>{t("Recruit.Copy")}</button>
          </div>

          {
              isCopies
              ? (
                <div className="position-absolute w-100 text-center"><p className="body-text-small">{t("Recruit.Copied")}</p></div>
              )
              : null
            }

          <div className="modal-content">
            <div className="d-flex justify-content-center mt-small">
              <div className="col-12">
                <p dangerouslySetInnerHTML={{__html: t(`Recruit.Description`) }} />
                <p className="body-text-small mb-small">
                {t("Recruit.DescriptionSmall")}
                </p>
              </div>
            </div>

            <div className="section-headline">
              <h6>{t("Recruit.YourCode")}</h6>
            </div>
            <ul className="list">
              <li>
                <div>
                  <div><span className="body-text">{recruitUrl}</span></div>
                </div>
              </li>
            </ul>

          </div>
        </React.Fragment>
      )}
    </Translation>
  );
};
