import React, { useContext } from 'react';
import { isTest } from '../../config.js';
import PersonContext from '../../PersonContext';

export const FreezeNo = () => {
    let personContext = useContext(PersonContext);
    let domain = isTest() ? 'staging.actic.no' : 'www.actic.no';
    let urlNo = "https://"+domain+"/fryse-medlemskap/?memberId=";
    let memberId = personContext.person.personId.center + 'p' + personContext.person.personId.id;

  return (
        <div className="row">
            <div className="col-12">
                <p className='caption-text mt-5 mb-5'>
                    Medlemmer kan fryse sitt medlemskap uten dokumentasjon i minimum 1 måned og maksimum 2 måneder, mot en engangs fryseavgift til enhver gjeldende sats.
Medlemskap kan fryses fra den 1. i påfølgende måned - altså ikke midt i en måned. Eventuell bindingstid forskyves tilsvarende fryseperioden.
Under fryseperioden vil ikke medlemsavgiften debiteres, og medlemskortet sperres for adgang til Actics treningsfasiliteter.
Medlemskapet åpnes automatisk dagen etter siste dato i fryseperioden.
                </p>
                <div className='text-center'>
                    <a className="btn secondary-btn color-secondary" href={urlNo + memberId} target="_blank" rel="noreferrer">Gå videre</a>
                </div>
            </div>
        </div>
    ) 
}
